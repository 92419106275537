import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import Card from "../UI/Card"
import CtaBanner from "../Common/CtaBanner"
import ServiceList from "../Common/ServiceList"
import HeadingBlock from "../UI/HeadingBlock"

Service.propTypes = {
	className: PropTypes.string.isRequired,
}

function Service({ className, coverImageUrl, imagePos = null, title, strapline, content }) {

	return (
		<div className={className}>
			<HeadingBlock>
				<h1>{title}</h1>
				<p>{strapline}</p>
			</HeadingBlock>
			
			<Card className="cover-image" style={{backgroundImage: `url(${coverImageUrl})`, backgroundPosition: imagePos }} />

			<Card className="service-info">
				<div dangerouslySetInnerHTML={{ __html: content }} />
			</Card>

			<CtaBanner />

			<ServiceList />


		</div>
	)
}
export default styled(Service)`${({ theme })=>css`

	.cover-image {
		height: 200px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		background-color: white;

		@media (min-width: ${theme.bp.md}) {
			height: 340px;
		}

		@media (min-width: ${theme.bp.lg}) {
			height: 400px;
		}
	}

	.service-info {

		padding-top: 1px;
		@media (min-width: ${theme.bp.md}) {
			padding-top: 8px;
		}
		@media (min-width: ${theme.bp.lg}) {
			padding-top: 24px;
		}
		
		ul {
			list-style: square;
			padding-left: 0;
			margin: 8px 0;
			padding: 32px 0;
			font-weight: 600;

			li {
				margin-bottom: 1em;
				padding-left: 0;
				margin-left: 24px;
				padding-right: 16px; 
				&:last-child {
					margin-bottom: 0;
				}
				line-height: 2;

			}

			@media (min-width: ${theme.bp.md}) {
				font-size: 20px;
				margin: 0;

				li {
					padding: 0 16px;
					margin-right: 16px;
					padding-right: 48px;
					margin-bottom: 2em;
					padding-left: 16px;
					margin-left: 32px;
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}

	.lead {
		
		strong {
			font-weight: 600;
		}
		margin-top: 16px;
		@media (min-width: ${theme.bp.md}) {
			margin-top: 20px;
		}
	}

	.details {

		h3 {
			margin-top: 32px; 
			@media (min-width: ${theme.bp.md}) {
				margin-top: 48px;
			}
		}

		

		.details__block {
			display: block;
		}

		@media (min-width: ${theme.bp.lg}) {
			display: flex;
			flex-wrap: wrap;
		
			.details__block {
				flex-basis: 50%;
				&:nth-child(odd) {
					padding-right: 32px;
				}
				&:nth-child(even) {
					padding-left: 32px;
				}
			}
		}
		
	}

`}`