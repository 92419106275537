import React from "react"
import Container from "../components/Common/Layout/Container"
import Header from "../components/Common/Header"
import Footer from "../components/Common/Footer"
import SEO from "../components/Common/Seo"
import Service from "../components/Service"
import Theme from "../components/Theme"


const ServicePage = ({ data }) => {
	const { imagePos, strapline, title } = data.markdownRemark.frontmatter
	const coverImageUrl = data.markdownRemark.frontmatter.coverImage.publicURL
	const content = data.markdownRemark.html
	
	return (
		<Theme>
		  <Header />
		  <Container>
			  <SEO title={title} />
			  <Service
				  	coverImageUrl={coverImageUrl}
					imagePos={imagePos}
					title={title}
					content={content}
					strapline={strapline}
			  />
		  </Container>
		  <Footer />
		</Theme>
	  )
}

export default ServicePage

export const pageQuery = graphql`
		query($path: String!) {
			markdownRemark(frontmatter: { path: { eq: $path } }) {
				html
				frontmatter {
					path
					title
					strapline
					index
					imagePos
					coverImage {
						publicURL
					}
				}
			}
		}
	`
